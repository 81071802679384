import {
  CodeToText
} from "element-china-area-data"; //省市区解码
import {
  loginOut
} from "@/api/index.js"
import {
  uploadFiles
} from "@/api/index_user"
import {
  DictionaryCode
} from "@/libs/dictionary_user.js"
import {
  getDict
} from "@/api/common/dict_user.js";

export const formatDateDetailsToDay = (dateStr) => {
  let date = new Date(dateStr)
  return date.getFullYear() + "-" + formatInteger(date.getMonth() + 1) + "-" + date.getDate()
}

export const formatInteger = (num, digit) => {
  var numSting = String(num)
  if (numSting.length >= digit) {
    return numSting;
  } else {
    var prefix = ""
    for (let i = 0; i < digit - numSting.length; i++) {
      prefix += "0"
    }
    return prefix + numSting
  }
}

export const formatDateTime = (datea) => {
  let date = new Date(Date.parse(datea));
  //alert(date);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  // var second = date.getSeconds();
  // second = second < 10 ? "0" + second : second;
  return y + "-" + m + "-" + d + " " + h + ":" + minute;
}


export const regionCode = (data) => {
  let arr = []
  if (data) {
    arr.push(data.slice(0, 2) + "0000")
    arr.push(data.slice(0, 4) + "00")
    arr.push(data)
  }
  return arr
}
export const changeOption = (event, form, kv) => {
  // for (let k in kv) {
  //   let v = kv[k];
  //   form[v] = event[k];
  // }
  form[kv[0]] = event.name;
  form[kv[1]] = event.value;
}

//地址选中后数据处理
export const handleChange = (event, form, name, code) => {
  for (const key in name) {
    switch (name[key]) {
      case "S":
        form[key] = CodeToText[event[0]];
        break;

      case "s":
        form[key] = CodeToText[event[1]];
        break;

      case "q":
        form[key] = CodeToText[event[2]];
        break;

      case "Ss":
        form[key] =
          CodeToText[event[0]] + "/" + CodeToText[event[1]];
        break;
      case "Ssq":
        form[key] =
          CodeToText[event[0]] +
          "/" +
          CodeToText[event[1]] +
          "/" +
          CodeToText[event[2]];
        break;

      default:
        break;
    }
  }

  for (const key in code) {
    switch (code[key]) {
      case "S":
        form[key] = event[0];
        break;

      case "s":
        form[key] = event[1];
        break;

      case "q":
        form[key] = event[2];
        break;

      default:
        break;
    }
  }
}

//验证手机号码
export const verifyPhone = (tel) => {
  var pattern = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/g;
  let res = {
    isRight: true,
    errorMess: ''
  };
  if (tel == '') {
    res.isRight = false;
    res.errorMess = '手机号为空';
    return res;
  }
  if (!pattern.test(tel)) {
    res.isRight = false;
    res.errorMess = '手机格式不正确';
    return res;
  }
  return res
}
//验证邮箱
export const verifyEmail = (email) => {
  var pattern = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
  let res = {
    isRight: true,
    errorMess: ''
  };
  if (email == '') {
    res.isRight = false;
    res.errorMess = '邮箱地址为空';
    return res;
  }
  if (!pattern.test(email)) {
    res.isRight = false;
    res.errorMess = '邮箱格式不正确';
    return res;
  }
  return res
}

//验证密码
export const verifyPassword = (password) => {
  var pattern = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,12}/;
  let res = {
    isRight: true,
    errorMess: ''
  };
  if (password == '') {
    res.isRight = false;
    res.errorMess = '密码为空';
    return res;
  }
  if (!pattern.test(password)) {
    res.isRight = false;
    res.errorMess = '密码格式不正确(6-12位数字、字母、特殊符号2种以上组合)';
    return res;
  }
  return res
}



//数据回显时转换
export const boundDefaultData = (sjzd, fromData, data) => {
  for (let k in data) {
    let list = data[k].split(',')
    for (let lineData of sjzd[k]) {
      for (const m of list) {
        if (fromData[m] && fromData[m] === lineData.value) {
          fromData[m + "_"] = lineData;
        }
      }

    }
  }
}


export const itemConfig = (tableName, datas, configs, newDict) => {
  let data = datas[tableName];
  let config = configs[tableName];
  if (config == undefined) {
    newDict[tableName] = data;
    return;
  }

  let list = []
  for (let item of data) {
    let newData = {}
    newData['name'] = item[config['name']]
    newData['value'] = item[config['value']]
    newData['details'] = item

    if (item.children) {
      itemConfig('children', item, config, newData)
    }
    list.push(newData)
  }
  newDict[tableName] = list;
}

//数据字典配置
export const changeName = (data) => {
  let newData = {}
  for (const key in data) {
    itemConfig(key, data, DictionaryCode, newData)

  }
  return newData
}

// 获取数据字典
export const getDictionary = (...list) => {
  return new Promise((resolve, reject) => {
    getDict(list.join(",")).then((res) => {
      if (res.status) {
        let data = res.data
        data = changeName(data)
        resolve(data)
      } else {
        reject(res)
      }
    });
  })
}

// 区域码转换
export const regionChange = (formData, list) => {
  for (const name of list) {
    let arr = regionCode(formData[name])
    formData[name + '_'] = arr
  }

}

//退出登录
export const logOut = () => {
  loginOut().then(res => {
    console.log(res)
    if (res.status) {
      window.location = "/login"
    }
  })
}

//倒计时
export const resetCode = (second, ksFun, jsFun) => {
  var timer = null;
  timer = setInterval(function () {
    second -= 1;
    if (second > 0) {
      if (typeof ksFun == 'function') {
        ksFun()
      }
    } else {
      clearInterval(timer);
      if (typeof jsFun == 'function') {
        jsFun()
      }
    }
  }, 1000);
}

//上传文件校验
export const checkFile = (file, astrict) => {
  let obj = {
    status: false,
    message: ''
  }
  if (file.size > astrict.size) {
    obj.message = '文件大小出错'
    return obj
  }
  let fileType = file.name.split(".")[file.name.split(".").length - 1];
  if (astrict.ext.indexOf(fileType) == -1) {
    obj.message = "文件类型错误"
    return obj;
  }
  obj.status = true
  return obj
}

//文件大小转换单位
export const unitsChange = (size) => {
  if ((size/1024) < 1) {
    return size+'b'
  }else if((size/1024/1024) < 1){
    return Math.round(size/1024) + 'kb'
  }else if((size/1024/1024/1024) ){
    return Math.round(size/1024/1024) + 'Mb'
  }
}


//文件上传
export const uploadFile = (file, fileRule, astrict) => {
  return new Promise((resolve, reject) => {
    let result = checkFile(file, astrict);
    if (result.status) {
      uploadFiles(fileRule, file).then((res) => {
        if (res.status) {
          resolve(res)
        }
      });
    } else {
      reject(result)
    }
  })
}