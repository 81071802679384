export const DictionaryCode = {
    dict_xzqh: {
        name: "ssmc",
        value: "ssdm",
        children: {
            name: "djsmc",
            value: "djsdm",
            children: {
                name: "qxmc",
                value: "qxdm",
            },
        },
    },
    dict_mz: {
        name: "mzmc",
        value: "mzm",
    },
    dict_zzmm: {
        name: "zzmmmc",
        value: "zzmmm",
    },
    dict_hyzt: {
        name: "hyztmc",
        value: "hyztm",
    },
    dict_gjdq: {
        name: "gjdqmc",
        value: "gjdqm",
    },
    dict_gatqw: {
        name: "gatqwmc",
        value: "gatqwm",
    },
    dict_jkzk: {
        name: "jkzkmc",
        value: "jkzkm",
    },
    dict_xyzj: {
        name: "xyzjmc",
        value: "xyzjm",
    },
    dict_xl: {
        name: "xlmc",
        value: "xlm",
    },
    dict_xxfs: {
        name: "xxfsmc",
        value: "xxfsm",
    },
    dict_xxxs: {
        name: "xxxsmc",
        value: "xxxsm",
    },
    dict_xw: {
        name: "xwmc",
        value: "xwm",
    },
    dict_zc: {
        name: "zcmc",
        value: "zcm",
    },
    dict_xx: {
        name: "xxmc",
        value: "xxm",
    },
    dict_zydm_bk: {
        name: "zymc",
        value: "zydm",
    },
    dict_zydm_yjs: {
        name: "zymc",
        value: "zydm",
    },
    dict_zydm_zk: {
        name: "zymc",
        value: "zydm",
    },
    dict_xb: {
        name: "xbmc",
        value: "xbm",
    },
    dict_gx: {
        name: "gxmc",
        value: "gxm",
    },
    dict_cye: {
        name: "cyemc",
        value: "cyem",
    },
    dict_sfzjlx:{
      name: "sfzjlxmc",
      value: "sfzjlxm",
    }
}